import myAxios from "./axios_news"

export const getBZList = params => {
  return myAxios.get("/audit/getBZList", { params })
}
export const getBXList = params => {
  return myAxios.get("/audit/getBXList", { params })
}

export const addAuditInfoById = params => {
  return myAxios.post("/audit/addAuditInfoById", params)
}
export const getAuditInfoById = params => {
  return myAxios.get("/audit/getAuditInfoById", { params })
}

export const addContractPathById = params => {
  return myAxios.post("/audit/addContractPath", params)
}

export const delInfo = params => {
  return myAxios.post("/audit/delInfo", params)
}
export const getYJSList = params => {
  return myAxios.get("/audit/getYJSList", { params })
}

export const updateYJSType = params => {
  return myAxios({
    method: "post",
    url: "/audit/updateYJSType",
    data: params
  })
}

export const pushYJSInfo = params => {
  const formData = new FormData()
  formData.append("id", params.id)
  return myAxios.post("/yjsapi/api/audit", formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export const contractCreate = formData => {
  return myAxios.post("/yjsapi/api/sign", formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}